import { useState } from 'react';
import axios from 'axios';
import { datadogLogs } from '@datadog/browser-logs';

const useChemicalMix = () => {
  const access_token = localStorage.getItem('auth-token');
  const [chemicals, setChemicals] = useState([]);
  const [chemicalMixes, setChemicalMixes] = useState([]);
  const [singleChemicalMix, setSingleChemicalMix] = useState([]);
  const [loading, setLoading] = useState(false);
  const [singleMixLoading, setSingleMixLoading] = useState(false);
  const [chemicalGroups, setChemicalGroups] = useState([])
  const [tags, setTags] = useState([])
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const addChemicalMix = async data => {
    setLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}chemical-mix/add`, data, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      console.log('ADD CHEMICALS', response.data);
      alert('Chemical Mix added successfully');
    } catch (error) {
      datadogLogs.logger.error('Error occurred', {}, error)
      alert(error.response.data.message);
    }
    setLoading(false);
  };

  const getAllChemicalMixes = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}chemical-mix`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      // console.log('ALL CHEMICAL MIXES', response.data);
      setChemicalMixes(response.data.data);
    } catch (error) {
      datadogLogs.logger.error('Error occurred', {}, error)
    }
    setLoading(false);
  };
  const getAllChemicals = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}chemical-inventory`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      console.log('ALL CHEMICALS', response.data);
      setChemicals(response.data.data.allChemicalInventory);
    } catch (error) {
      datadogLogs.logger.error('Error occurred', {}, error)
    }
    setLoading(false);
  };

  const getSingleChemicalMix = async id => {
    setSingleMixLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}chemical-mix/single/${id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      console.log('SINGLE CHEMICAL MIX', response.data);
      setSingleChemicalMix(response.data.data);
    } catch (error) {
      datadogLogs.logger.error('Error occurred', {}, error)
    }
    setSingleMixLoading(false);
  };

  const updateChemicalMix = async (id, data) => {
    try {
      const response = await axios.put(`${BASE_URL}/chemical-mix/update/${id}`, data, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      console.log('UPDATE CHEMICAL MIX ENDPOINT', response.data);
      alert('Chemical Updated Successfully');
    } catch (error) {
      datadogLogs.logger.error('Error occurred', {}, error)
      alert(error.message);
    }
  };

  const addSubChemicalMix = async () => {};

  const addChemicalToInventory = async data => {
    setLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}chemical-inventory/add`, data, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      console.log(response.data);
      alert(response.data.message);
      setLoading(false);
      return true
    } catch (error) {
      alert(error.err.details[0].message);
      console.log(error)
      setLoading(false);
      datadogLogs.logger.error('Error occurred', {}, error)
      return false
    }
  }

  const getChemicalGroup = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}chemical-group`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      console.log(response.data.data);
      setChemicalGroups(response.data.data.allChemicalGroup)
    } catch (error) {
      datadogLogs.logger.error('Error occurred', {}, error)
      alert("An Error Occurred");
    }
    setLoading(false);
  }

  const getTags = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}tag`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      console.log(response.data.data);
      setTags(response.data.data.allTags)
    } catch (error) {
      datadogLogs.logger.error('Error occurred', {}, error)
    }
    setLoading(false);
  }

  const updateChemical = async (chemId, chemData) => {
    setLoading(true);
    try {
      const response = await axios.put(`${BASE_URL}chemical-inventory/update?chemicalId=${chemId}`, chemData, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      console.log(response.data.data);
      alert("Successfully updated chemical")
    } catch (error) {
      datadogLogs.logger.error('Error occurred', {}, error)
      alert(error.response.data.message);
    }
    setLoading(false);
  }

  return {
    chemicals,
    loading,
    addChemicalMix,
    addSubChemicalMix,
    updateChemicalMix,
    getAllChemicalMixes,
    getSingleChemicalMix,
    getAllChemicals,
    chemicalMixes,
    singleChemicalMix,
    singleMixLoading,
    addChemicalToInventory,
    getChemicalGroup,
    chemicalGroups, 
    getTags,
    tags,
    updateChemical
  };
};

export default useChemicalMix;
